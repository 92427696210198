.add-radio {
      font-family: "Calibri", sans-serif !important;
      font-weight: 500;
      text-transform: none;
      letter-spacing: 0.4px;
      margin-right : 75px;
      
}

.custom-radio{
      cursor: pointer;
      color: #344054;
      font-size: 16px;
}


