.select_table {
      font-family: 'Calibri', sans-serif !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      letter-spacing: 0em;
      color: #344054 !important;
}

.Redeem_ {
      font-family: 'Calibri', sans-serif !important;;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 28px;
}

.Cancle_button {
      font-family: 'Calibri', sans-serif !important;;
      font-size: 14px !important;
      font-weight: 600 !important;
      line-height: 24px;
      text-transform: none !important;
      width: 100%;
      border: 1px solid #d0d5dd !important;
      color: #344054 !important;
      letter-spacing: .02em !important;
}

.Aplly_button {
      background: #f25042 !important;
      color: #fff !important;
      font-family: 'Calibri', sans-serif !important;;
      font-size: 14px !important;
      font-weight: 600 !important;
      letter-spacing: .03em !important;
      text-transform: none !important;
      width: 100%;
      line-height: 24px;
}