.title{
      font-size: 16px;
      color: #3F4254;
      font-weight: 600;
}

.Name_{
      font-size: 16px;
      color: #667085;
      font-weight: 400;
}

.table-header {
      font-family: 'Inter', sans-serif;
      font-size: 20px;
      color: #667085;
    }

    .button_group_view {
      background: #7878801F !important;
      border: 0px;
      border-radius: 8px;
  }

  .navigation-icon {
      cursor: pointer;
      font-size: 24px;
      color: #555;
      transition: color 0.3s ease;
  }

  .disabled {
      color: #ccc;
      pointer-events: none;
      cursor: not-allowed;
  }
