.switch-container {
      display: flex;
      align-items: center;
      gap: 10px;
      /* Space between label and switch */
}

.switch-label {
      font-size: 16px;
      color: #344054 !important;
      font-weight: 500;
      /* margin-bottom: 6px !important; */
      font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}



.copybutton {
      background: transparent !important;
      color: rgb(255, 255, 255) !important;
      border: 1px solid !important;
      border-color: hsl(210deg 1.98% 56.98% / 50%) !important;
      border-radius: 6px !important;
      padding: 5px !important;
      text-transform: none !important;
      font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;

}


.switch small {
      display: inline-block;
      width: 43px;
      height: 18px;
      background: #ccc;
      border-radius: 30px;
      position: relative;
      cursor: pointer;
}

.switch small:after {
      content: "No";
      position: absolute;
      color: #fff;
      font-size: 11px;
      font-weight: 600;
      width: 100%;
      left: 0px;
      text-align: right;
      padding: 0 6px;
      box-sizing: border-box;
      line-height: 18px;
}

.switch small:before {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      background: #fff;
      border-radius: 50%;
      top: 3px;
      left: 3px;
      transition: .4s;
      box-shadow: -3px 0 3px rgba(0, 0, 0, 0.1);
}

.switch input:checked~small {
      background: #F25042;
      transition: .4s;
}

.switch input:checked~small:before {
      transform: translate(25px, 0px);
      transition: .4s;
}

.switch input:checked~small:after {
      content: "Yes";
      text-align: left;
}

.switchSmall {
      display: inline-block;
}

.switchSmall input {
      display: none;
}

.switchSmall small {
      display: inline-block;
      width: 43px;
      height: 25px;
      background: #ccc;
      border-radius: 30px;
      position: relative;
      cursor: pointer;
}

.switchSmall small:before {
      content: "";
      position: absolute;
      width: 19px;
      height: 19px;
      background: #fff;
      border-radius: 50%;
      top: 3px;
      left: 3px;
      transition: .4s;
      box-shadow: -3px 0 3px rgba(0, 0, 0, 0.1);
}

.switchSmall input:checked~small {
      background: #F25042;
      transition: .4s;
}

.switchSmall input:checked~small:before {
      transform: translate(16px, 0px);
      transition: .4s;
}
