.type_name {
      font-family: "Calibri", sans-serif !important;
      font-weight: 600 !important;
      color: #344054 !important;
      text-transform: none !important;
      letter-spacing: 0.4px !important;
      font-size: 14px !important;
}

.week_day{
      font-family: "Calibri", sans-serif;
      font-weight: 500;
      color: #344054;
      text-transform: none;
      letter-spacing: 0.4px;
      font-size: 18px; 
      text-align: center;  
}


